<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
        <v-row>

          <v-col cols="12" sm="6" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                clearable
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap;">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(dates);
                    updateDateFilter();
                  "
                  >Aceptar</v-btn
                >
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="12" md="2">
            <v-btn
              color="success"
              class="ma-2 white--text"
              @click="getByDates"
              block
            >
              Filtrar <v-icon right >
                fas fa-filter
              </v-icon>
            </v-btn>
        </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="2">
            <v-btn
                class="ma-2"
                outlined
                block
                color="green darken-3"
                @click="exportToExcel"
              >
                Exportar Excel
                <v-icon right>
                  fas fa-file-excel
                </v-icon>
              </v-btn>
          </v-col>

        </v-row>
    </v-container>

      <!-- :items="currentItems" -->
    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:[`item.date_created`]="{ item }">
          <div>
            {{ dateFormat(item.date_created) }}
          </div>
      </template>
      <template v-slot:[`item.nombre`]="{ item }">
          <div v-if="item.nombre">
            {{ item.nombre }}
          </div>
          <div v-else>
            {{ item.nombre2 }}
          </div>
      </template>
      <template v-slot:[`item.valor`]="{ item }">
          <div>
            {{ numberWithCommas(parseFloat(item.valor).toFixed(2)) }}
          </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="success" class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>

      <template slot="body.append">

      <tr class="gray--text">
        <!-- <th class="title"></th> -->
        
        <th colspan="9" class="totales">
        <v-row class="pt-2">

          <v-col cols="12" sm="12" md="4">
            Total: {{ numberWithCommas((parseFloat(sumField('valor'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
      </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import ExcelJS from 'exceljs';
// import * as XLSX from 'xlsx';
// import XLSX from 'xlsx/dist/xlsx.full.min.js';
import { saveAs } from 'file-saver';

export default {
  name: "constance",
  data: () => ({
    dialog: false,
    dialogNote: false,
    samplings: [],
    currentItems: [],
    sumItems: [],
    items: [],
    customers: [],
    process: "",
    type: "",
    total_sum: "",
    id_customer: null,
    dates: [],
    modal: false,
    loader: null,
    loading3: false,
    dialogDelete: false,
    search: "",
    loading: false,
    titulo: "DECLARACIÓN MENSUAL DE COMPRAS",
    headers: [
      // { text: "Fecha", align: "left", value: "id"},
      { text: "Fecha", align: "left", value: "date_created"},
      { text: "RTN", align: "left", value: "rtn" },
      { text: "Nombre", align: "left", value: "nombre" },
      { text: "Número de Comprobante", value: "receipt" },
      { text: "Valor", value: "valor" },
      // { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    editedIndex: -1,

    valid: true,
    

    editedItem: {
      id_provider: null,
      receipt: "",
      selected: [],
      id_providerRules: [v => !!v || "Escoge un Productor"],
    },
    defaultItem: {
      id_provider: null,
      receipt: "",
      selected: [],
      id_providerRules: [v => !!v || "Escoge un Productor"],
    },
  }),

  computed: {
    
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      await axios.get(this.db + 'sar',
        {
          headers: this.headers_db
        }).then(response => {
          let datos2 = response.data.data;
          this.samplings = datos2;
          // console.log(datos2);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });

        await axios.get(this.db + 'sar_retentions_partner',
        {
          headers: this.headers_db
        }).then(response => {
          let datos = response.data.data;
          this.currentItems = this.samplings.concat(datos);
          let repetidos = [];
          this.currentItems.forEach(element => {
            if (element.rtn == null) {
              element.rtn = element.rtn2;
            }
            if (element.nombre2 == null) {
              element.nombre2 = element.name2;
            }
            if (element.receipt == "") {
              element.receipt = element.comment;
            }
            if (element.valor == null) {
              element.valor = element.quantity;
            }
            repetidos.push(parseInt(element.receipt));
          });

          repetidos.sort((a, b) => a - b);
          console.log(repetidos);
          repetidos.forEach(element => {
            console.log(element);
          });
          this.samplings = this.currentItems;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          // this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });
    },
    // FIN Metodos base de datos

    // Metodos Generales
    
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    title() {
      return "Selecciona las fechas a filtrar";
    },
    updateDateFilter() {
      if (this.dates.length > 1) {
        if (moment(this.dates[0], "YYYY-MM-DD") > new Date(this.dates[1])) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      } else {
        this.dates = [this.dates[0], this.dates[0]];
      }
    },
    
    sumField(key) {
      return this.currentItems.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    eliminar() {
      this.deleteProcess(this.editedItem.id);
      this.close();
    },

    getByDates() {
      console.log('dates', this.dates);
      if (this.dates[0] && this.dates[1]) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.date_created).format('YYYY-MM-DD');
          console.log(date_db);
          return date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },

    async exportToExcel() {
        console.log("Exportando");
        const data = this.currentItems;

        data.forEach(element => {
          console.log(element.date_created);
          element.date = moment(element.date_created).format('DD/MM/YYYY');
          element.compraOce = "NO";
          element.document = 4;
          if (element.rtn != null) {
            element.rtn2 = element.rtn.replace(/-/g, '');
          }
          element.valor = parseFloat(element.valor);
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Hoja1');

        // const selectedColumns = ['date_created','rtn','nombre2', 'receipt','valor'];
        const columns = [
          { key: 'document', header: 'Tipo de Documento' , width: 5 },
          { key: 'date', header: 'FECHA' , width: 30, height: 25 },
          { key: 'rtn2', header: 'RTN' , width: 40 },
          { key: 'nombre2', header: 'NOMBRE COMPLETO', width: 60 },
          { key: 'receipt', header: 'NÚMERO DE COMPROBANTE', width: 60 },
          { key: 'compraOce', header: 'OCE' , width: 10 },
          { key: 'field1', header: '' , width: 5 },
          { key: 'field2', header: '' , width: 5 },
          { key: 'valor', header: 'VALOR' , width: 30, style: { numFmt: '#,##0.00' } },
        ];
      worksheet.columns = columns;
      // Establece los encabezados de columna personalizados en la hoja de cálculo
      const headerRow = worksheet.addRow(columns.map(column => column.header));
      headerRow.font = { bold: true, size: 14, name: 'Arial' };
      headerRow.height = 25;
      headerRow.eachCell(cell => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });

      // Agrega los datos a la hoja de cálculo
      data.forEach(obj => {
        const rowData = columns.map(column => obj[column.key]);
        const dataRow = worksheet.addRow(rowData);
        dataRow.font = { size: 11, name: 'Calibri' };
        dataRow.height = 20;
        dataRow.eachCell(cell => {
          cell.alignment = { vertical: 'middle', horizontal: 'left' };
        });
      });
        // Genera el archivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'DECLARACIÓN MENSUAL DE COMPRAS.xlsx');
    },
  },
  async mounted() {
    this.loading = true;
    await this.getSamplings();
  },
};
</script>

<style lang="scss">
  .totales {
    font-size: medium !important;
  }
</style>
