var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.titulo)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.dates,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Filtrar por fecha","prepend-icon":"fa-calendar-alt","readonly":"","filled":"","rounded":"","clearable":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","title-date-format":_vm.title,"scrollable":"","max":_vm.today},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-row',{staticStyle:{"flex-wrap":"nowrap"},attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.dates);
                    _vm.updateDateFilter();}}},[_vm._v("Aceptar")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"success","block":""},on:{"click":_vm.getByDates}},[_vm._v(" Filtrar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-filter ")])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","block":"","color":"green darken-3"},on:{"click":_vm.exportToExcel}},[_vm._v(" Exportar Excel "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-file-excel ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.currentItems,"search":_vm.search,"sort-by":"id","sort-desc":"","loading":_vm.loading,"loading-text":"Consultando datos... Espere por favor"},scopedSlots:_vm._u([{key:"item.date_created",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.dateFormat(item.date_created))+" ")])]}},{key:"item.nombre",fn:function(ref){
                    var item = ref.item;
return [(item.nombre)?_c('div',[_vm._v(" "+_vm._s(item.nombre)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.nombre2)+" ")])]}},{key:"item.valor",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.numberWithCommas(parseFloat(item.valor).toFixed(2)))+" ")])]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("fas fa-edit")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("fas fa-trash-alt")])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"gray--text"},[_c('th',{staticClass:"totales",attrs:{"colspan":"9"}},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_vm._v(" Total: "+_vm._s(_vm.numberWithCommas((parseFloat(_vm.sumField('valor'))).toFixed(2)))+" ")])],1)],1)])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }